@font-face {
    font-family: 'lato';
    src: url('../fonts/lato-regular.woff2') format('woff2'),
    url('../fonts/lato-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('../fonts/lato-bold-webfont.woff2') format('woff2'),
    url('../fonts/lato-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    height: 100%;
    background: #1769a6;
    background: -moz-linear-gradient(-45deg, #1769a6 0%, #accfe9 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#1769a6), color-stop(100%,#accfe9));
    background: -webkit-linear-gradient(-45deg, #1769a6 0%,#accfe9 100%);
    background: -o-linear-gradient(-45deg, #1769a6 0%,#accfe9 100%);
    background: -ms-linear-gradient(-45deg, #1769a6 0%,#accfe9 100%);
    background: linear-gradient(135deg, #1769a6 0%,#accfe9 100%);
    background-attachment: fixed;
    font-family: 'lato', sans-serif!important;
    color: #fff;
    text-align: center;
}

#title-item {
    background: #fff;
    color: #000;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: 'latobold';
}

@media all and (max-width:768px) {
    #title-item {
        background: #1769a6;
        color: #fff;
    }
}

h2, h3 {
    color: #1769a6;
    letter-spacing: 0.5px;
    font-family: 'latobold', sans-serif!important;
}

#menu {
    border-radius: 0px !important;
    margin: 0;
}

/* Content */
#content {
    background: #fff;
    padding: 2em 1.2em;
    margin-bottom: 1.5em;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    color: #000000;
}

#main-content {
    overflow: hidden;
}

#main-content h3 {
    margin: 2em 0 1em 0 !important;
}

#main-content p.spaced {
    margin: 2em 0;
}

/* Footer */
#footer {
    border-bottom: 0px;
    margin-bottom: 1em;
}

#footer a:link, #footer a:visited {
    color: #fff;
    text-decoration: none;
}

#footer a:hover {
    text-decoration: underline;
}

#footer label {
    color: #fff;
}
#footer .field.error label {
    color: #9f3a38;
}

/* Nags */
#cookie-nag a {
    color: #fff;
    text-decoration: underline;
}
#cookie-nag a:hover {
    text-decoration: none;
}

.warning {
    margin: 2em 0;
    display: block;
    color: #FF4500 !important;
    font-weight: bold;
}

.warning a {
    color: #FF4500 !important;
    text-decoration: underline;
    font-family: 'latobold';
    letter-spacing: 0.5px;
}

.ui.form .error.message {
    display: block;
}